// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import isNull from 'lodash/isNull'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { compiler } from 'markdown-to-jsx'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** ResourceRenderer */
const ResourceRenderer = ({
  resource: { resource, caption, subType = '' },
}) => (
  <Fragment>
    {isNull(caption) === true && (
      <GatsbyImage className={subType} image={getImage(resource)} />
    )}
    {isNull(caption) === false && (
      <div className={`with-caption ${subType}`}>
        <GatsbyImage image={getImage(resource)} />
        <p className="caption">{compiler(caption, { wrapper: null })}</p>
      </div>
    )}
  </Fragment>
)

// ----------------------------------------------------------------------------
// ----------------------------------------------------------- Compose & Export
// ----------------------------------------------------------------------------
/** Compose ala FP style - Add Box, Link */
export default ResourceRenderer
